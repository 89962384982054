import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: `${process.env.GATSBY_FIREBASE_API_KEY}`,
  authDomain: `${process.env.GATSBY_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.GATSBY_FIREBASE_PROJECT_ID}`,
  storageBucket:`${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.GATSBY_FIREBASE_MESSAGEING_SENDER_ID}`,
  appId: `${process.env.GATSBY_FIREBASE_APP_ID}`,
  measurementId: `${process.env.GATSBY_FIREBASE_MEASUREMENT_ID}`
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)