/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import "firebase/auth"
import { AuthProviderGoogle } from './src/firebase/auth'

export const wrapRootElement = ({ element }) => (
  <AuthProviderGoogle>{element}</AuthProviderGoogle>
)