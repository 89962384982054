import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

export const AuthContextGoogle = React.createContext({})

export function AuthProviderGoogle({ children }) {
  const [currentUser, setCurrentUser] = useState(null)

  const signout = async () => {
    await signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser)
    })
    return unsubscribe
  }, [])

  // This value object will be returned when useAuth() is imported
  const value = {
    currentUser, 
    setCurrentUser,
    signout
  }

  return (
    <AuthContextGoogle.Provider value={value} >
      {children}
    </AuthContextGoogle.Provider>
  );
}
